import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import HashAuthenticationTest from '../components/HashAuthenticationTest/HashAuthenticationTest';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <InlineNotification kind="warning" mdxType="InlineNotification">
      <p><strong parentName="p">{`Deprecation Warning:`}</strong>{` This API is deprecated and it is scheduled to be removed on 03/01/2022. Please use (or migrate to) the new version of the `}<a parentName="p" {...{
          "href": "/viewer-authentication-api-v2-getting-started"
        }}>{`Viewer Authentication`}</a>{`.`}</p>
    </InlineNotification>
    <h2>{`Test your authentication`}</h2>
    <p>{`On this page you can test your Channel Secret Key and the JSON object containing the authentication hash.
Learn how to obtain these in the `}<a parentName="p" {...{
        "href": "/viewer-authentication-api-v2-getting-started"
      }}>{`Viewer Authentication API documentation`}</a>{`.`}</p>
    <HashAuthenticationTest mdxType="HashAuthenticationTest" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      